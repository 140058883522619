<template>
  <div class="container">
    <div class="top">
      <div class="main-title">专注儿童主持表演教育15年</div>
      <div class="sub-title">线上线下 简单有趣</div>
    </div>
    <img
      class="fulllogo"
      src="/static/img/full-logo.png"
      v-animate="'animate__animated animate__fadeIn'"
      style="--animate-duration: 5s"
    />
    <img
      class="people"
      src="/static/img/index/statistics/people.png"
      v-animate="'animate__animated animate__fadeInUp'"
    />

    <div class="statistics">
      <img
        src="/static/img/index/statistics/shape_1.png"
        v-animate="'animate__animated animate__fadeInUp'"
      />
      <img
        src="/static/img/index/statistics/shape_2.png"
        v-animate="'animate__animated animate__fadeInUp'"
      />
      <img
        src="/static/img/index/statistics/shape_3.png"
        v-animate="'animate__animated animate__fadeInUp'"
      />
      <img
        src="/static/img/index/statistics/shape_4.png"
        v-animate="'animate__animated animate__fadeInUp'"
      />
    </div>
    <div>
      <img
        class="chat"
        src="/static/img/index/statistics/chat.png?v=1"
        v-animate="'animate__animated animate__fadeInUp animate__delay-1s'"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  background-image: url("/static/img/index/statistics/bg.png");
  background-size: 100% 858px;
  background-repeat: no-repeat;
  height: 858px;
  position: relative;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;
    }
    .sub-title {
      margin-top: 10px;
      font-size: $font-size-16;
      color: $font-color-gray;
    }
  }
  .statistics {
    display: flex;
    margin: 50px 200px 0 200px;

    img {
      margin: 0 40px;
      width: 180px;
      height: 180px;
    }
  }

  .chat {
    width: 377px;
    height: 167px;
    margin-left: 340px;
    margin-top: 40px;
  }
  .people {
    position: absolute;
    top: 380px;
    right: 170px;
    width: 567px;
    height: 575px;
  }

  .fulllogo {
    position: absolute;
    bottom: 100px;
    right: 300px;
    width: 120px;
  }
}
</style>